<template>
  <div class="hello">
    <header class="bg-white border-b border-gray-200">
      <div class="container px-2 sm:px-2 lg:px-2">
        <div class="flex justify-between h-16">
          <div class="flex ml-2 mr-auto xml-0">
            <div class="-my-px ml-8 flex space-x-10">
              <router-link
                :to="{ name: 'datasets' }"
                :class="[
                  getActiveClass('datasets'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Датасеты
              </router-link>

              <!-- Assuming you also have a route for 'models', if not you should adjust this -->
              <router-link
                :to="{ name: 'training' }"
                :class="[
                  getActiveClass('models'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Модели
              </router-link>
              <router-link
                :to="{ name: 'video' }"
                :class="[
                  getActiveClass('video'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Видео
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="mt-2">
      <div class="mt-2 p-4">
        <div class="flex items-center space-x-4">
          <input type="file" ref="fileInput" class="border p-2 rounded" />
          <button
            @click="uploadFile"
            class="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
          >
            Загрузить
          </button>
          <div
            v-if="loading"
            class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8"
          ></div>
        </div>
      </div>
      <div class="mt-4">
        <table class="min-w-full bg-white">
          <thead class="bg-gray-800 text-white">
            <tr>
              <th class="py-2 px-4">ID</th>
              <th class="py-2 px-4">Имя файла</th>
              <th class="py-2 px-4">Дата</th>
              <th class="py-2 px-4">Статус</th>
              <th class="py-2 px-4">email</th>
              <th class="py-2 px-4">Действия</th>
            </tr>
          </thead>
          <tbody class="text-gray-700">
            <tr v-for="video in videos" :key="video.id">
              <td class="border py-2 px-4">{{ shortenName(video.name) }}</td>
              <td class="border py-2 px-4">{{ video.original_name }}</td>
              <td class="border py-2 px-4">
                {{ formatDate(video.created_at) }}
              </td>
              <td class="border py-2 px-4">{{ video.status }}</td>
              <td class="border py-2 px-4">{{ video.email }}</td>
              <td class="border py-2 px-4">
                <!-- Edit Button -->
                <button
                  @click="showFrames(video)"
                  class="mr-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700 inline-block"
                >
                  <i class="fas fa-edit"></i> Просмотреть фреймы
                </button>
                <!-- Remove Button -->
                <button
                  @click="addToDataset(video)"
                  class="mr-2 px-4 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700 inline-block"
                >
                  <i class="fas fa-plus"></i> Добавить к датасету
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="showModal"
      class="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50"
    >
      <div
        class="bg-white p-6 rounded shadow-lg max-w-3xl max-h-full overflow-auto m-4 md:w-[640px]"
      >
        <!-- Close button: Making it vivid and bigger -->
        <button
          @click="hideModal"
          class="float-right flex items-center justify-center flex-row text-white bg-red-500 hover:bg-red-600 p-2 rounded-full w-10 h-10 leading-10 text-center"
        >
          <img src="@/assets/close.svg" alt="Close" />
        </button>

        <h3 class="text-lg mb-4 mt-6">Фреймы</h3>
        <div class="flex flex-wrap">
          <!-- Image with borders, margin, and set dimensions -->
          <img
            v-for="frame in currentVideoFrames"
            :src="frame"
            alt="Video Frame"
            class="border rounded-xl border-gray-300 m-2 w-48 h-48 object-cover"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showDatasetDialog"
      class="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50"
    >
      <div
        class="bg-white p-6 rounded shadow-lg max-w-3xl max-h-full overflow-auto m-4 md:w-[640px]"
      >
        <button
          @click="showDatasetDialog = false"
          class="flex items-center justify-center text-white bg-red-500 hover:bg-red-600 transition-colors duration-300 p-2 rounded-full w-10 h-10 leading-tight text-xl"
        >
          <!-- <div>&times;</div> -->
          <img src="@/assets/close.svg" alt="Close" />
        </button>

        <h3 class="text-lg mb-4 mt-6">Выберите датасет</h3>
        <table class="min-w-full bg-white shadow-md rounded">
          <thead>
            <tr class="text-gray-700 border-b">
              <th class="py-2 px-4">Название датасета</th>
              <th class="py-2 px-4"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dataset in datasets" :key="dataset.id">
              <td class="py-2 px-4 border-b border-gray-200 text-left">
                {{ dataset.id }}: {{ dataset.title }} 
              </td>
              <td class="py-2 px-4 border-b border-gray-200 text-right">
                <div class="flex items-center justify-center">
                  <div v-if="ifAdded(dataset.id)">уже добавлен</div>
                  <button
                    v-show="dataset.progress == 0"
                    v-if="!ifAdded(dataset.id)"
                    @click="addFramesToDataset(dataset.id)"
                    class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700 mr-2"
                  >
                    Добавить
                  </button>
                  <div
                    v-if="dataset.progress > 0"
                    class="loader border-4 border-dotted h-6 w-6 rounded-full border-t-transparent animate-spin mr-2"
                  ></div>
                  <span v-if="dataset.progress > 0" class="text-sm"
                    >{{ dataset.progress.toFixed(0) }}%</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { createClient } from "@supabase/supabase-js";
import { Upload } from "tus-js-client";
import tableSets from "@/components/tablesets";
import modelsSets from "@/components/modelsets";
import api from "@/lib/api";

const SUPABASE_URL = "https://supabase.ar-toir.ru/";

const SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJzZXJ2aWNlX3JvbGUiLAogICAgImlzcyI6ICJzdXBhYmFzZS1kZW1vIiwKICAgICJpYXQiOiAxNjQxNzY5MjAwLAogICAgImV4cCI6IDE3OTk1MzU2MDAKfQ.DaYlNEoUrrEn2Ig7tqibS-PHK5vgusbcbo7X36XVt4Q";

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
const supabaseUploadURL = `${SUPABASE_URL}storage/v1/upload/resumable`;

export default {
  name: "HelloWorld",
  data() {
    return {
      mode: "datasets",
      loading: false,
      videos: [],
      mode: "video",
      showModal: false,
      currentVideoFrames: [],
      datasets: [],
      showDatasetDialog: false,
      selectedVideo: null,
      progress: 0,
    };
  },
  mounted() {
    this.fetchDatasets(); // Fetch datasets on mount

    this.fetchVideos();
  },
  computed: {
    
  },
  components: {
    tableSets,
    modelsSets,
  },
  methods: {
    ifAdded(dataset_id){
      if (this.selectedVideo){
        if (Array.isArray(this.selectedVideo.projects)){
          return this.selectedVideo.projects.includes(dataset_id)
        } else {
          return false
        }
      } else {
        return false
      }
    },
    shortenName(name) {
      return name.slice(0, 4);
    },
    formatDate(datetime) {
      const options = {
        day: "numeric",
        month: "long",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(datetime).toLocaleDateString("ru-RU", options);
    },
    async uploadChunked(bucketName, fileName, file) {
      return new Promise((resolve, reject) => {
        let vm = this;
        if (!file) return;
        let tuss = window.tus;

        // const upload = new tuss.Upload(file, {
        const upload = new Upload(file, {
          // endpoint: "https://media.ar-toir.ru/uploads/",
          endpoint: "https://uploader.ar-toir.ru/uploads/",
          // endpoint: supabaseUploadURL,
          retryDelays: [0, 1000, 3000, 5000],
          canStoreURLs: false,
          // uploadDataDuringCreation: true,
          removeFingerprintOnSuccess: true,
          headers: {
            Authorization: `Bearer ${SUPABASE_ANON_KEY}`,
          },
          metadata: {
            bucketName: bucketName,
            filename: fileName,
            contentType: file.type,
            cacheControl: 3600,
          },
          chunkSize: 2 * 1024 * 1024,
          onError: (error) => {
            console.log("Failed because: " + error);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            this.uploadProgress = percentage;
            console.log(percentage);
          },
          onSuccess: () => {
            console.log("File uploaded with ID:", upload.url);
            this.uploadProgress = null;
            this.$refs.fileInput.value = ""; // Reset file input
            vm.$data.loading = false; // stop loading
          },
        });

        upload.start();
      });
    },
    fetchDatasets() {
      api
        .getDatasets()
        .then((response) => {
          // Initialize each dataset with a progress property
          this.datasets = response.data?.results.map((dataset) => ({
            ...dataset,
            progress: 0,
          }));
          console.log(this.datasets);
        })
        .catch((error) => {
          console.error("There was an error fetching the datasets:", error);
        });
    },
    async addToDataset(video) {
      this.selectedVideo = video; // Store the selected video for reference
      if (!this.selectedVideo.frames.length){
        alert('Видео не обработано, пожалуйста, подождите')
      } else if (this.selectedVideo.frames.length == 0){
        alert('Видео не обработано, пожалуйста, подождите')
      }
      try {
        this.showDatasetDialog = true; // Show the dialog
      } catch (error) {
        console.error("There was an error fetching the datasets:", error);
      }
    },
    async addFramesToDataset(datasetId) {
      for (let i = 0; i < this.selectedVideo.frames.length; i++) {
        const imageUrl = this.selectedVideo.frames[i];
        await api.addImage({ id: datasetId, imageUrl });
        this.datasets = this.datasets.map((dataset) => {
          if (dataset.id === datasetId) {
            return {
              ...dataset,
              progress: Math.round(
                ((i + 1) / this.selectedVideo.frames.length) * 100
              ),
            };
          }
          return dataset;
        });
      }
      api.updateVideo({
        video_id: this.selectedVideo.id,
        dataset_id: datasetId,
      });
      // Reset progress for the specific video
      this.datasets = this.datasets.map((dataset) => {
        if (dataset.id === datasetId) {
          return { ...dataset, progress: 0 };
        }
        return dataset;
      });
      this.showDatasetDialog = false;
      this.selectedVideo = null;
    },
    showFrames(video) {
      this.currentVideoFrames = video.frames;
      this.showModal = true;
    },

    hideModal() {
      this.showModal = false;
      this.currentVideoFrames = [];
    },
    async fetchVideos() {
      try {
        const response = await api.getVideo();
        this.videos = response.data
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .map((video) => ({ ...video })); // Initialize progress for each video
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    },
    async uploadFile() {
      const file = this.$refs.fileInput.files[0];
      if (!file) return;

      this.loading = true; // start loading

      try {
        // await this.uploadChunked('videos', 'source/' + file.name, file);
        await this.uploadChunked("videos", file.name, file);
        console.log("File uploaded:", file.name);

        // Send the request to create a new video record after the file upload is successful
        const videoData = {
          name: file.name,
          status: "queue",
        };
        const response = await api.createVideo(videoData);
        console.log("Video record created:", response.data);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        this.loading = false; // stop loading
      }
    },
    async uploadFileOld() {
      const file = this.$refs.fileInput.files[0];
      if (!file) return;

      this.loading = true; // start loading

      let { data, error } = await supabase.storage
        .from("videos")
        .upload("source/" + file.name, file);

      this.loading = false; // stop loading

      if (error) {
        console.error("Error uploading file:", error);
      } else {
        console.log("File uploaded:", data);
      }
    },
    getActiveClass(expectedMode) {
      if (this.mode === expectedMode) {
        return {
          "text-indigo-600": true,
          "border-b-2": true,
          "border-indigo-600": true,
        };
      } else {
        return {
          "text-gray-500": true,
          "border-b-2": true,
          "border-transparent": true,
          "hover:border-gray-300": true,
          "hover:text-gray-900": true,
        };
      }
    },
  },
  props: {
    msg: String,
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}
</style>
  