<template>
  <div
    class="fixed inset-0 w-full h-full z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="relative w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg">
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-black hover:text-red-600"
      >
        &times;
      </button>
      <h2 class="text-xl font-bold mb-4">Ошибки валидации</h2>
      <div class="overflow-y-auto max-h-96">
        <ul>
          <li
            v-for="(mistake, index) in mistakes"
            :key="index"
            class="mb-4 p-4 border-b text-left"
          >
            <p>
              Вероятность:<b>{{ mistake.p }}</b>
            </p>
            <p>
              Ссылка:
              <a
                :href="mistake.ur"
                class="text-blue-600 underline"
                target="_blank"
                >{{ mistake.ur }}</a
              >
            </p>
            <p>
              ошибочный класс: <b>{{ mistake.myclass }}</b>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mistakes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Добавьте любые пользовательские стили здесь */
</style>
