<template>
  <div class="hello">
    <my-modal :dataset="dataset" @close="closeModal" v-if="showModal" />
    <div class="py-6 bg-white" v-if="list">
      <div class="px-4 max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-4xl">
          <div
            class="overflow-hidden bg-white border border-gray-200 rounded-xl"
          >
            <div class="divide-y divide-gray-200">
              <div
                class="grid grid-cols-4 py-4 gap-y-4 lg:gap-0"
                v-for="result in displayList"
                :key="result.id"
              >
                <div class="col-span-1 px-2">
                  <span
                    v-if="result.status === 'idle'"
                    class="text-xs font-medium text-gray-900 bg-gray-100 rounded-full inline-flex items-center px-2.5 py-1"
                  >
                    <svg
                      class="-ml-1 mr-1.5 h-2.5 w-2.5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    idle
                  </span>
                  <span
                    v-else-if="result.status === 'тренируется'"
                    class="text-xs font-medium text-green-900 bg-green-100 rounded-full inline-flex items-center px-2.5 py-1"
                  >
                    <svg
                      class="-ml-1 mr-1.5 h-2.5 w-2.5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    Тренируется
                  </span>
                </div>

                <div class="px-4 lg:py-4 sm:px-6">
                  <p class="text-sm font-bold text-gray-900">
                    {{ result.title }}
                  </p>
                  <p class="mt-1 text-sm font-medium text-gray-500">
                    {{ result.description || "Описание отсутствует" }}
                  </p>
                </div>
                <div class="px-2">
                  <p class="text-sm font-bold text-gray-900">
                    {{ result.num_tasks_with_annotations }}/{{
                      result.task_number
                    }}
                  </p>
                  <p class="mt-1 text-sm font-medium text-gray-500">
                    {{
                      new Date(result.created_at).toLocaleDateString("ru-RU")
                    }}
                  </p>
                </div>
                <div class="px-2 text-right" v-if="result.showButton">
                  <button
                    @click="openModal(result)"
                    type="button"
                    class="inline-flex items-center justify-center w-full px-12 py-3 text-sm font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  >
                    Начать тренировку
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/lib/api";
import myModal from "@/components/myModal";
export default {
  name: "mytablesetsKek",
  components: {
    myModal,
  },
  data() {
    return {
      showModal: false,
      list: [],
      dataset: {},
      models: [],
    };
  },
  props: {
    msg: String,
  },
  computed: {
    displayList() {
      return this.list.map((dataset) => {
        const relatedModels = this.models.filter(
          (model) => model.dataset_id == dataset.id
        );

        let status = "idle"; // default status
        if (relatedModels.length) {
          const isTraining = relatedModels.some(
            (model) => model.training_status !== "ready"
          );
          if (isTraining) {
            status = "тренируется";
          }
        }

        const isModelTraining = relatedModels.some(
          (model) => model.training_status != "ready"
        );
        return {
          ...dataset,
          status: status,
          showButton: !isModelTraining,
        };
      });
    },
  },
  methods: {
    
    closeModal() {
      this.$data.showModal = false;
      this.getData();
    },
    openModal(result) {
      this.$data.dataset = result;
      this.showModal = true;
    },
    toggleModal(val) {
      this.showModal = val;
    },
    getData() {
      api.getModels().then((response) => {
        this.models = response.data; // Store models data
        console.log(this.models);
      });
      api
        .getDatasets()
        .then((response) => {
          this.list = response.data?.results;
        })
        .catch((error) => {
          console.error("There was an error fetching the datasets:", error);
        });
    },
  },
  mounted() {

    this.getData();
  },
};
</script>
