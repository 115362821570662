<template>
  <div class="hello">
    <my-modal-analysis
      :dataset="dataset"
      @close="closeModal"
      v-if="showModal"
    />
    <validation-modal
      :mistakes="validationData"
      @close="closeValidationModal"
      v-if="showValidationModal"
    />
    <div class="py-6 bg-white" v-if="list">
      <div class="px-4 max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-4xl">
          <div
            class="overflow-hidden bg-white border border-gray-200 rounded-xl"
          >
            <div class="divide-y divide-gray-200">
              <div
                class="grid grid-cols-4 py-4 gap-y-4 lg:gap-0"
                v-for="result in displayList"
                :key="result.id"
              >
                <div class="col-span-1 px-2">
                  <span
                    v-if="
                      result.status === 'idle' ||
                      result.status === 'Анализ завершен'
                    "
                    class="text-xs font-medium text-gray-900 bg-gray-100 rounded-full inline-flex items-center px-2.5 py-1"
                  >
                    <svg
                      class="-ml-1 mr-1.5 h-2.5 w-2.5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    idle
                  </span>
                  <span
                    v-else-if="result.status === 'Идет обработка'"
                    class="text-xs font-medium text-green-900 bg-green-100 rounded-full inline-flex items-center px-2.5 py-1"
                  >
                    <svg
                      class="-ml-1 mr-1.5 h-2.5 w-2.5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                    Идет обработка
                  </span>
                </div>

                <div class="px-4 lg:py-4 sm:px-6">
                  <p class="text-sm font-bold text-gray-900">
                    {{ result.title }}
                  </p>
                  <p class="mt-1 text-sm font-medium text-gray-500">
                    {{ result.description || "Описание отсутствует" }}
                  </p>
                </div>
                <div class="px-2">
                  <p class="text-sm font-bold text-gray-900">
                    {{ result.num_tasks_with_annotations }}/{{
                      result.task_number
                    }}
                  </p>
                  <p class="mt-1 text-sm font-medium text-gray-500">
                    {{
                      new Date(result.created_at).toLocaleDateString("ru-RU")
                    }}
                  </p>
                </div>
                <div
                  class="px-2 text-right"
                  v-if="result.showButton || result.showViewResultsButton"
                >
                  <button
                    v-if="result.showButton"
                    @click="openModal(result)"
                    type="button"
                    class="inline-flex items-center justify-center w-full px-12 py-3 text-sm font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  >
                    Начать анализ датасета
                  </button>
                  <button
                    v-if="result.showViewResultsButton"
                    @click="viewResults(result)"
                    type="button"
                    class="inline-flex items-center justify-center w-full px-12 py-3 text-sm font-medium text-white transition-all duration-200 bg-green-600 border border-transparent rounded-xl hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 mt-2"
                  >
                    Просмотреть результаты
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/lib/api";
import myModalAnalysis from "@/components/myModalAnalysis";
import ValidationModal from "@/components/ValidationModal"; // Import the modal component

export default {
  name: "mytablesetsKek",
  components: {
    myModalAnalysis,
    ValidationModal, // Register the modal component
  },
  data() {
    return {
      showModal: false,
      showValidationModal: false, // Control for the validation modal
      list: [],
      dataset: {},
      analysis: [], // Store analysis (including validations) data here
      validationData: [], // Store validation data for the modal
    };
  },
  props: {
    msg: String,
  },
  computed: {
    displayList() {
      return this.list.map((dataset) => {
        const relatedModels = this.analysis.filter(
          (model) => model.dataset_id == dataset.id
        );

        let status = "idle"; // default status
        let showButton = false;
        let showViewResultsButton = false;

        if (relatedModels.length > 0) {
          const isQueueOrExport = relatedModels.some(
            (model) => model.status === "queue" || model.status === "export"
          );

          const isDone = relatedModels.some((model) => model.status === "done");

          if (isQueueOrExport) {
            status = "Идет обработка";
          } else if (isDone) {
            status = "Анализ завершен";
            showButton = true;
            showViewResultsButton = true; // Show "Просмотреть результаты" button
          } else {
            status = "тренируется";
          }
        } else {
          // No related models found, show "Начать анализ датасета" button
          showButton = true;
        }

        return {
          ...dataset,
          status: status,
          showButton: showButton,
          showViewResultsButton: showViewResultsButton,
        };
      });
    },
  },
  methods: {
    viewResults(result) {
      // Filter analysis by dataset_id
      const datasetAnalysis = this.$data.analysis.filter(
        (analysis) => analysis.dataset_id === result.id
      );
      if (datasetAnalysis.length > 0) {
        let validation = datasetAnalysis[0];
        console.log(validation);

        this.$data.validationData = validation.mistakes; // Store validation data
        this.$data.showValidationModal = true; // Show the validation modal
      }
    },
    closeModal() {
      this.$data.showModal = false;
      this.getData();
    },
    openModal(result) {
      this.$data.dataset = result;
      this.showModal = true;
    },
    toggleModal(val) {
      this.showModal = val;
    },
    closeValidationModal() {
      this.showValidationModal = false;
    },
    getData() {
      api.getAnalysis().then((response) => {
        this.analysis = response.data; // Store analysis (including validations) data
        console.log(this.analysis);
      });
      api
        .getDatasets()
        .then((response) => {
          this.list = response.data?.results;
        })
        .catch((error) => {
          console.error("There was an error fetching the datasets:", error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
