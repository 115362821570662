<template>
  <div class="hello">
    <div class="mt-2">
      <div v-if="id">
        <table-sets :id="id" />
        <models-sets :id="id" />
      </div>
    </div>
  </div>
</template>

<script>
import tableSets from "@/components/single/info";
import modelsSets from "@/components/single/models";
export default {
  name: "HelloWorld",
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    console.log("mounted");
    this.$data.mode = this.stage;
    console.log(this.$route.params.id);
    if (!this.$route.params.id) {
      alert("no id ⚠️");
    } else {
      this.$data.id = this.$route.params.id;
    }
  },
  components: {
    tableSets,
    modelsSets,
  },
  methods: {
    getActiveClass(expectedMode) {
      if (this.mode === expectedMode) {
        return {
          "text-indigo-600": true,
          "border-b-2": true,
          "border-indigo-600": true,
        };
      } else {
        return {
          "text-gray-500": true,
          "border-b-2": true,
          "border-transparent": true,
          "hover:border-gray-300": true,
          "hover:text-gray-900": true,
        };
      }
    },
  },
  props: {
    stage: String,
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
