<template>
    <div class="hello">
      <div class="py-1 ">
        <div class="px-4 max-w-7xl sm:px-6 lg:px-8">
          <table class="min-w-full  border divide-y divide-gray-200 rounded-xl">
            <thead>
              <tr>
                <th class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Описание модели
                </th>
                <th class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase">
                  Статус
                </th>
                <th class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase">
                  Веса нейросети
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="model in models" :key="model.id" v-show="model.dataset_id == id">
                <td class="px-6 py-4 text-left">
                  {{ model.about }}
                </td>
                <td class="px-6 py-4 text-left">
                  {{ model.training_status }}
                  <span v-show="model.training_status == 'ready'"> ✅</span>
                </td>
                <td class="px-6 py-4 text-right" v-if="model.training_status === 'ready'">
                  <a :href="model.weights_link_default" target="_blank" class="mr-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700 inline-block">
                    model.pt
                  </a>
                  <a :href="model.weights_link_mobile" target="_blank" class="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded hover:bg-green-700 inline-block">
                    model.tflite
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from "@/lib/api";
  import myModal from "@/components/myModal";
  export default {
    name: "mytablesetsKek",
    components: {
      myModal,
    },
    data() {
      return {
        showModal: false,
        list: [],
        dataset: {},
        models : []
      };
    },
    props: {
      id : Number,
      msg: String,
    },
    computed: {
      displayList() {
      return this.list.map(dataset => {
        const relatedModels = this.models.filter(model => model.dataset_id == dataset.id);
        
        let status = 'idle'; // default status
        if (relatedModels.length) {
          const isTraining = relatedModels.some(model => model.training_status !== 'ready');
          if (isTraining) {
            status = 'тренируется';
          }
        }
        
        const isModelTraining = relatedModels.some(model => model.training_status != "ready");
        return {
          ...dataset,
          status: status,
          showButton: !isModelTraining
        };
      });
    }
    },
    methods: {
      updateModels(){

      },
      closeModal(){
        this.$data.showModal = false;
      },
      openModal(result) {
        this.$data.dataset = result;
        this.showModal = true;
      },
      toggleModal(val) {
        this.showModal = val;
      },
    },
    mounted() {
      api
        .getModels()
        .then((response) => {
          this.models = response.data; // Store models data
          console.log(this.models)
        })
      api
        .getDatasets()
        .then((response) => {
          this.list = response.data?.results;
        })
        .catch((error) => {
          console.error("There was an error fetching the datasets:", error);
        });
      
    },
  };
  </script>
  