<template>
  <div class="home h-screen border">
    <SingleDataset stage="datasets" msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import SingleDataset from "@/components/SingleDataset.vue";

export default {
  name: "HomeView",
  components: {
    SingleDataset,
  },
};
</script>
