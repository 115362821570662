import { createRouter, createWebHashHistory } from "vue-router";
import DatasetsView from "../views/DatasetsView.vue";
import TrainingsView from "../views/TrainingsView.vue";
import VideoView from "../views/Video.vue";
import AnalysisView from "../views/Analysis.vue";

import OneDatasetPage from "../views/OneDatasetPage.vue";

const routes = [
  {
    path: "/onedataset/:id",
    name: "onedataset",
    component: OneDatasetPage,
  },
  {
    path: "/datasets",
    name: "datasets",
    component: DatasetsView,
  },
  {
    path: "/analysis",
    name: "analysis",
    component: AnalysisView,
  },
  {
    path: "/training",
    name: "training",
    component: TrainingsView,
  },
  {
    path: "/video",
    name: "video",
    component: VideoView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/datasets",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
