<template>
  <div class="hello">
    <div class="py-6 bg-white">
      <div class="px-4 max-w-7xl sm:px-6 lg:px-8">
        <table
          class="min-w-full bg-white border divide-y divide-gray-200 rounded-xl"
        >
          <thead>
            <tr>
              <th
              class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase"
            >
              Model_ID
            </th>
              <th
                class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase"
              >
                Dataset_ID
              </th>
              <th
                class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase"
              >
                Название датасета
              </th>
              <th
                class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase"
              >
                Статус
              </th>
              <th
                class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase"
              >
                Описание
              </th>
              <th
                class="px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase"
              >
                Тип модели
              </th>
              <th
                class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="model in models" :key="model.id">
              <td class="px-6 py-4">
                {{ model.id }}
              </td>
              <td class="px-6 py-4">
                {{ model.dataset_id }}
              </td>
              <td class="px-6 py-4">
                {{ model.dataset_title }}
              </td>
              <td class="px-6 py-4">
                {{ model.training_status }}
              </td>
              <td class="px-6 py-4">
                {{ model.about }}
              </td>
              <td class="px-1 py-4">
                <p v-if="model.type">{{ model.type }}</p>
                <p v-if="!model.type">yolov8n.pt</p>
              </td>
              <td
                class="px-6 py-4 text-right"
                v-if="model.training_status === 'ready'"
              >
                <a
                  :href="model.weights_link_default"
                  target="_blank"
                  class="m-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700 inline-block"
                >
                  model.pt
                </a>
                <a
                  :href="model.weights_link_mobile"
                  target="_blank"
                  class="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded hover:bg-green-700 inline-block"
                >
                  model.tflite
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
import api from "@/lib/api";
import myModal from "@/components/myModal";
export default {
  name: "mytablesetsKek",
  components: {
    myModal,
  },
  data() {
    return {
      showModal: false,
      list: [],
      dataset: {},
      models: [],
    };
  },
  props: {
    msg: String,
  },
  computed: {
    displayList() {
      return this.list.map((dataset) => {
        const relatedModels = this.models.filter(
          (model) => model.dataset_id == dataset.id
        );

        let status = "idle"; // default status
        if (relatedModels.length) {
          const isTraining = relatedModels.some(
            (model) => model.training_status !== "ready"
          );
          if (isTraining) {
            status = "тренируется";
          }
        }

        const isModelTraining = relatedModels.some(
          (model) => model.training_status != "ready"
        );
        return {
          ...dataset,
          status: status,
          showButton: !isModelTraining,
        };
      });
    },
  },
  methods: {
    updateModels() {},
    closeModal() {
      this.$data.showModal = false;
    },
    openModal(result) {
      this.$data.dataset = result;
      this.showModal = true;
    },
    toggleModal(val) {
      this.showModal = val;
    },
  },
  mounted() {
    api
      .getModels()
      .then((response) => {
        // Assuming response.data is an array of models and each model has a `created_at` property
        this.models = response.data.sort((a, b) => {
          // Convert created_at to Date objects if they're not already, and compare
          return new Date(b.created_at) - new Date(a.created_at);
        });
        console.log(this.models); // Store and log sorted models data
      })
      .catch((error) => {
        console.error("There was an error fetching the models:", error);
      });

    api
      .getDatasets()
      .then((response) => {
        this.list = response.data?.results;
      })
      .catch((error) => {
        console.error("There was an error fetching the datasets:", error);
      });
  },
};
</script>
  