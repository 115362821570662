<template>
  <div class="h-[100%] w-full bg-gray-100 z-[5000] fixed left-0 top-0">
    <div
      class="flex items-center justify-center w-full h-full px-4 py-5 sm:p-6"
    >
      <div class="w-full max-w-sm bg-white shadow-lg rounded-xl">
        <div class="px-4 py-5 sm:p-6">
          <p class="text-xl font-bold text-gray-900">Тренировка нейросети</p>
          <p class="mt-3 text-sm font-medium text-gray-500">
            Датасет - {{ dataset?.title }}
          </p>

          <div class="mt-6">
            <div class="space-y-4">
              <div>
                <label for="modelSelect" class="text-sm font-bold text-gray-900"
                  >Выберите модель YOLO</label
                >
                <select
                  v-model="selectedModel"
                  id="modelSelect"
                  class="block w-full mt-2 px-4 py-3 border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="yolov8n.pt">Nano</option>
                  <option value="yolov8s.pt">Small</option>
                  <option value="yolov8m.pt">Medium</option>
                  <option value="yolov8l.pt">Large</option>
                  <option value="yolov8x.pt">X-Large</option>
                </select>
              </div>
              <div>
                <label for="" class="text-sm font-bold text-gray-900">
                  описание модели
                </label>
                <div class="mt-2">
                  <input
                    v-model="about_model"
                    type="text"
                    name=""
                    id=""
                    placeholder="Версия 1"
                    class="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                  />
                </div>
              </div>
            </div>

            <div class="flex items-center justify-end mt-5 space-x-4">
              <button
                @click="closeModal"
                type="reset"
                class="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
              >
                Отмена
              </button>

              <button
                @click="create_model"
                type="submit"
                class="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-500"
              >
                Начать тренировку
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import api from "@/lib/api";
export default {
  name: "HelloWorld",
  data() {
    return {
      selectedModel: 'yolov8n.pt',
      about_model: "",
    };
  },
  methods: {
    create_model() {
      console.log(this.dataset);
      console.log(this.$data.about_model);
      // return;
      if (this.dataset?.task_number < 20) {
        alert("Недостаточно данных для тренировки");
        return;
      }
      let new_model = {
        dataset_id: this.dataset.id,
        dataset_title: this.dataset.title,
        about: this.$data.about_model,
        type  : this.$data.selectedModel
      };
      api.createTrainingModel(new_model).then((res) => {
        console.log(res);
        this.$emit("close");
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
  components: {},
  props: {
    dataset: Object,
    msg: String,
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
  