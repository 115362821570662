<template>
  <div class="hello">
    <header class="bg-white border-b border-gray-200">
      <div class="container px-2 sm:px-2 lg:px-2">
        <div class="flex justify-between h-16">
          <div class="flex ml-2 mr-auto xml-0">
            <div class="-my-px ml-8 flex space-x-10">
              <router-link
                :to="{ name: 'datasets' }"
                :class="[
                  getActiveClass('datasets'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Датасеты
              </router-link>

              <!-- Assuming you also have a route for 'models', if not you should adjust this -->
              <router-link
                :to="{ name: 'training' }"
                :class="[
                  getActiveClass('models'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Модели
              </router-link>
              <router-link
                :to="{ name: 'video' }"
                :class="[
                  getActiveClass('video'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Видео
              </router-link>
              <router-link
                :to="{ name: 'analysis' }"
                :class="[
                  getActiveClass('analysis'),
                  'inline-flex items-center px-1 pt-1 text-sm font-medium transition-all duration-200',
                ]"
              >
                Анализ
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="bg-red-200 mt-2">
      <div>
        <table-sets v-if="mode == 'datasets'" />
        <models-sets v-if="mode == 'models'" />
        <analysis v-if="mode == 'analysis'" />
      </div>
    </div>
  </div>
</template>

<script>
import tableSets from "@/components/tablesets";
import modelsSets from "@/components/modelsets";
import analysis from "@/components/analysis";
export default {
  name: "HelloWorld",
  data() {
    return {
      mode: "datasets",
    };
  },
  mounted() {
    console.log("mounted");
    this.$data.mode = this.stage;
  },
  components: {
    tableSets,
    modelsSets,
    analysis,
  },
  methods: {
    getActiveClass(expectedMode) {
      if (this.mode === expectedMode) {
        return {
          "text-indigo-600": true,
          "border-b-2": true,
          "border-indigo-600": true,
        };
      } else {
        return {
          "text-gray-500": true,
          "border-b-2": true,
          "border-transparent": true,
          "hover:border-gray-300": true,
          "hover:text-gray-900": true,
        };
      }
    },
  },
  props: {
    stage: String,
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
