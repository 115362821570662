// API.js
import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.ar-toir.ru",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  addImage(data) {
    return apiClient.post("/images", data);
  },
  validateDataset(data) {
    return apiClient.post("/analysis/validate-dataset", data);
  },
  updateVideo(data) {
    return apiClient.post("/update-video", data);
  },
  getDatasets() {
    return apiClient.get("/datasets");
  },
  getModels() {
    return apiClient.get("/models");
  },
  getAnalysis() {
    return apiClient.get("/analysis");
  },
  createTrainingModel(data) {
    return apiClient.post("/models", data);
  },
  createVideo(data) {
    return apiClient.post("/videos", data);
  },
  getVideo() {
    return apiClient.get("/videos");
  },
};
